import { sample } from "lodash";
export const shuffleArray = (array) => {
  if (!Array.isArray(array)) {
    return [];
  }
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const fillGraph = (data, min = 12) => {
  const totalSlides = data.length;
  const slidesToAdd = Math.ceil(totalSlides / min) * min - totalSlides;
  let slidesData = [...data];
  for (let i = 0; i < slidesToAdd; i++) {
    slidesData.push(sample(data));
  }
  return slidesData;
};

export const shuffleEveryMinutes = (arr, time = 12000) => {
  setInterval(() => {
    const shuffled = shuffleArray([...arr]);
    arr.splice(0, arr.length, ...shuffled);
    console.log("Mảng đã được trộn lại:", arr);
  }, time);
};
